import { useState } from "react"

export enum messageLastUpdateReason {
  none,
  loadingOldMessages,
}
export interface IUseMessagerHook {
  allMessageHistoryFetched: boolean
  messageBatchOffset: number
  lastUpdateReason: messageLastUpdateReason
  setLastUpdateReason: (reason: messageLastUpdateReason) => void
  setAllMessageHistoryFetched: (bool: boolean) => void
  setMessageBatchOffset: (bool: number) => void
}

export const useMessagerHook = (): IUseMessagerHook => {
  const [lastUpdateReason, setUpdateReason] = useState(
    messageLastUpdateReason.none
  )
  const [allMessageHistoryFetched, setAllMessageHistoryFetched] =
    useState(false)
  const [messageBatchOffset, setMessageBatchOffset] = useState<number>(0)

  const setLastUpdateReason = (
    lastUpdateReason: messageLastUpdateReason
  ): void => {
    setUpdateReason(lastUpdateReason)
  }

  return {
    lastUpdateReason,
    setLastUpdateReason,
    allMessageHistoryFetched,
    setAllMessageHistoryFetched,
    messageBatchOffset,
    setMessageBatchOffset,
  }
}
