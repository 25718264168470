import React, { Dispatch } from "react"
import { IMessageProps } from "../components/Atoms/Chat/Message"
import { ActionMap } from "./types"
import { MyWebsocket } from "../websockets/websocket"
import { IChatAlertProps } from "../components/Atoms/Chat/Alert"

export const initialWebsocketContextState: MessageType = {
  websocket: undefined,
  messages: [],
  otherProfileUUID: "",
  unreadMessageCount: 0,
  otherUserTyping: false,
  reconnecting: false,
}

export enum MessageActionTypes {
  NewWebsocket = "NEWWEBSOCKET",
  PrivateMessage = "PRIVATEMESSAGE",
  AlertMessage = "ALERTMESSAGE",
  ChatHistory = "CHATHISTORY",
  PrependChatHistory = "PREPENDCHATHISTORY",
  ChatConnected = "CHATCONNECTED",
  UpdateUnreadMessageCount = "UPDATEUNREADMESSAGECOUNT",
  OtherUserTyping = "OTHERUSERTYPING",
  Reconnecting = "RECONNECTING",
}

export type MessageType = {
  websocket: MyWebsocket | undefined
  messages: (IChatAlertProps | IMessageProps)[]
  otherProfileUUID: string
  unreadMessageCount: number
  otherUserTyping: boolean
  reconnecting: boolean
}

type MessagePayload = {
  [MessageActionTypes.NewWebsocket]: {
    websocket: MyWebsocket
  }
  [MessageActionTypes.PrivateMessage]: {
    privateMessage: IMessageProps
  }
  [MessageActionTypes.AlertMessage]: {
    alertMessage: IChatAlertProps
  }
  [MessageActionTypes.ChatHistory]: {
    historyPrivateMessages: (IChatAlertProps | IMessageProps)[]
  }
  [MessageActionTypes.PrependChatHistory]: {
    historyPrivateMessages: (IChatAlertProps | IMessageProps)[]
  }
  [MessageActionTypes.ChatConnected]: {
    otherProfileUUID: string
  }
  [MessageActionTypes.UpdateUnreadMessageCount]: {
    unreadMessageCount: number
  }
  [MessageActionTypes.OtherUserTyping]: {
    otherUserTyping: boolean
  }
  [MessageActionTypes.Reconnecting]: {
    reconnecting: boolean
  }
}

export type MessageActions =
  ActionMap<MessagePayload>[keyof ActionMap<MessagePayload>]

export function websocketContextReducer(
  state: MessageType = initialWebsocketContextState,
  action: MessageActions
): MessageType {
  switch (action.type) {
    case MessageActionTypes.NewWebsocket:
      if (state.websocket !== undefined) {
        state.websocket.close()
      }
      return {
        ...state,
        websocket: action.payload.websocket,
      }
    case MessageActionTypes.PrivateMessage:
      return {
        ...state,
        messages: [...state.messages, action.payload.privateMessage],
      }
    case MessageActionTypes.AlertMessage:
      return {
        ...state,
        messages: [...state.messages, action.payload.alertMessage],
      }
    case MessageActionTypes.ChatHistory:
      return {
        ...state,
        messages: action.payload.historyPrivateMessages,
      }
    case MessageActionTypes.PrependChatHistory:
      return {
        ...state,
        messages: [...action.payload.historyPrivateMessages, ...state.messages],
      }
    case MessageActionTypes.ChatConnected:
      return {
        ...state,
        otherProfileUUID: action.payload.otherProfileUUID,
      }

    case MessageActionTypes.UpdateUnreadMessageCount:
      return {
        ...state,
        unreadMessageCount: action.payload.unreadMessageCount,
      }

    case MessageActionTypes.OtherUserTyping:
      return {
        ...state,
        otherUserTyping: action.payload.otherUserTyping,
      }

    case MessageActionTypes.Reconnecting:
      return {
        ...state,
        reconnecting: action.payload.reconnecting,
      }

    default:
      return {
        ...state,
      }
  }
}

export const MessageContext = React.createContext<{
  messageContext: MessageType
  messageDispatch: Dispatch<MessageActions>
}>({
  messageContext: initialWebsocketContextState,
  messageDispatch: () => null,
})
