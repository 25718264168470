import {
  UserSocialAccountType,
  VERIFICATION_METHODS,
} from "../contexts/userSocialAccounts"

import { v4 as uuidv4 } from "uuid"

export const oneThousand = 1000
export const oneMillion = 1000000
export const oneBillion = 1000000000

export const presetAccounts: UserSocialAccountType[] = [
  {
    accountName: "OnlyFans",
    userName: "",
    isVerified: false,
    blossmUsername: "",
    blossmProfileUrl: "",
    verificationCode: "",
    verificationDmConfirmed: false,
    verifyByDirectMessage: false,
    verifyByCodeInBio: false,
    verifyByLogin: false,
    verifyByFansMetric: true,
    key: uuidv4(),
    uuid: "",
    verificationState: {
      view: VERIFICATION_METHODS.CodeInBio,
      includeVerifyBy: true,
    },
    canVerifyStats: true,
    statVerificationExampleVideoUrl: "",
  },
  {
    accountName: "Instagram",
    userName: "",
    isVerified: false,
    blossmUsername: "",
    blossmProfileUrl: "",
    verificationCode: "",
    verificationDmConfirmed: false,
    verifyByDirectMessage: false,
    verifyByCodeInBio: false,
    verifyByLogin: false,
    verifyByFansMetric: false,
    key: uuidv4(),
    uuid: "",
    verificationState: {
      view: VERIFICATION_METHODS.CodeInBio,
      includeVerifyBy: true,
    },
    canVerifyStats: true,
    statVerificationExampleVideoUrl: "",
  },
  {
    accountName: "X/Twitter",
    userName: "",
    isVerified: false,
    blossmUsername: "",
    blossmProfileUrl: "",
    verificationCode: "",
    verificationDmConfirmed: false,
    verifyByDirectMessage: false,
    verifyByCodeInBio: false,
    verifyByLogin: false,
    verifyByFansMetric: false,
    key: uuidv4(),
    uuid: "",
    verificationState: {
      view: VERIFICATION_METHODS.CodeInBio,
      includeVerifyBy: true,
    },
    canVerifyStats: true,
    statVerificationExampleVideoUrl: "",
  },
  {
    accountName: "TikTok",
    userName: "",
    isVerified: false,
    blossmUsername: "",
    blossmProfileUrl: "",
    verificationCode: "",
    verificationDmConfirmed: false,
    verifyByDirectMessage: false,
    verifyByCodeInBio: false,
    verifyByLogin: false,
    verifyByFansMetric: false,
    key: uuidv4(),
    uuid: "",
    verificationState: {
      view: VERIFICATION_METHODS.CodeInBio,
      includeVerifyBy: true,
    },
    canVerifyStats: true,
    statVerificationExampleVideoUrl: "",
  },
]

// List of route -> page names for pages that can be access by anon users.
export const unauthenticatedRouteToPageMap: { [key: string]: string } = {
  "/": "home",
  "/help": "help",
  "/login": "login",
  "/[name]": "profile",
}

export const messagesWidth = 475
export const minPasswordLength = 10
export const passwordRequirementsMessage =
  "Must be at least 10 characters and" +
  " contain upper case, lower case, numbers, and symbols."

interface ICountryDetail {
  alpha2Code: string
  alpha3Code: string
  numericCode: string
  name: string
}

export const countriesInDetail: ICountryDetail[] = [
  {
    alpha2Code: "US",
    alpha3Code: "USA",
    numericCode: "840",
    name: "United States",
  },
  {
    alpha2Code: "GB",
    alpha3Code: "GBR",
    numericCode: "826",
    name: "United Kingdom",
  },
  { alpha2Code: "CA", alpha3Code: "CAN", numericCode: "124", name: "Canada" },
  {
    alpha2Code: "AF",
    alpha3Code: "AFG",
    numericCode: "004",
    name: "Afghanistan",
  },
  {
    alpha2Code: "AX",
    alpha3Code: "ALA",
    numericCode: "248",
    name: "Aland Islands",
  },
  { alpha2Code: "AL", alpha3Code: "ALB", numericCode: "008", name: "Albania" },
  { alpha2Code: "DZ", alpha3Code: "DZA", numericCode: "012", name: "Algeria" },
  {
    alpha2Code: "AS",
    alpha3Code: "ASM",
    numericCode: "016",
    name: "American Samoa",
  },
  { alpha2Code: "AD", alpha3Code: "AND", numericCode: "020", name: "Andorra" },
  { alpha2Code: "AO", alpha3Code: "AGO", numericCode: "024", name: "Angola" },
  { alpha2Code: "AI", alpha3Code: "AIA", numericCode: "660", name: "Anguilla" },
  {
    alpha2Code: "AQ",
    alpha3Code: "ATA",
    numericCode: "010",
    name: "Antarctica",
  },
  {
    alpha2Code: "AG",
    alpha3Code: "ATG",
    numericCode: "028",
    name: "Antigua and Barbuda",
  },
  {
    alpha2Code: "AR",
    alpha3Code: "ARG",
    numericCode: "032",
    name: "Argentina",
  },
  { alpha2Code: "AM", alpha3Code: "ARM", numericCode: "051", name: "Armenia" },
  { alpha2Code: "AW", alpha3Code: "ABW", numericCode: "533", name: "Aruba" },
  {
    alpha2Code: "AU",
    alpha3Code: "AUS",
    numericCode: "036",
    name: "Australia",
  },
  { alpha2Code: "AT", alpha3Code: "AUT", numericCode: "040", name: "Austria" },
  {
    alpha2Code: "AZ",
    alpha3Code: "AZE",
    numericCode: "031",
    name: "Azerbaijan",
  },
  {
    alpha2Code: "BS",
    alpha3Code: "BHS",
    numericCode: "044",
    name: "The Bahamas",
  },
  { alpha2Code: "BH", alpha3Code: "BHR", numericCode: "048", name: "Bahrain" },
  {
    alpha2Code: "BD",
    alpha3Code: "BGD",
    numericCode: "050",
    name: "Bangladesh",
  },
  { alpha2Code: "BB", alpha3Code: "BRB", numericCode: "052", name: "Barbados" },
  { alpha2Code: "BY", alpha3Code: "BLR", numericCode: "112", name: "Belarus" },
  { alpha2Code: "BE", alpha3Code: "BEL", numericCode: "056", name: "Belgium" },
  { alpha2Code: "BZ", alpha3Code: "BLZ", numericCode: "084", name: "Belize" },
  { alpha2Code: "BJ", alpha3Code: "BEN", numericCode: "204", name: "Benin" },
  { alpha2Code: "BM", alpha3Code: "BMU", numericCode: "060", name: "Bermuda" },
  { alpha2Code: "BT", alpha3Code: "BTN", numericCode: "064", name: "Bhutan" },
  { alpha2Code: "BO", alpha3Code: "BOL", numericCode: "068", name: "Bolivia" },
  {
    alpha2Code: "BA",
    alpha3Code: "BIH",
    numericCode: "070",
    name: "Bosnia and Herzegovina",
  },
  { alpha2Code: "BW", alpha3Code: "BWA", numericCode: "072", name: "Botswana" },
  {
    alpha2Code: "BV",
    alpha3Code: "BVT",
    numericCode: "074",
    name: "Bouvet Island",
  },
  { alpha2Code: "BR", alpha3Code: "BRA", numericCode: "076", name: "Brazil" },
  {
    alpha2Code: "IO",
    alpha3Code: "IOT",
    numericCode: "086",
    name: "British Indian Ocean Territory",
  },
  {
    alpha2Code: "BN",
    alpha3Code: "BRN",
    numericCode: "096",
    name: "Brunei Darussalam",
  },
  { alpha2Code: "BG", alpha3Code: "BGR", numericCode: "100", name: "Bulgaria" },
  {
    alpha2Code: "BF",
    alpha3Code: "BFA",
    numericCode: "854",
    name: "Burkina Faso",
  },
  { alpha2Code: "BI", alpha3Code: "BDI", numericCode: "108", name: "Burundi" },
  { alpha2Code: "KH", alpha3Code: "KHM", numericCode: "116", name: "Cambodia" },
  { alpha2Code: "CM", alpha3Code: "CMR", numericCode: "120", name: "Cameroon" },
  {
    alpha2Code: "CV",
    alpha3Code: "CPV",
    numericCode: "132",
    name: "Cape Verde",
  },
  {
    alpha2Code: "KY",
    alpha3Code: "CYM",
    numericCode: "136",
    name: "Cayman Islands",
  },
  {
    alpha2Code: "CF",
    alpha3Code: "CAF",
    numericCode: "140",
    name: "Central African Republic",
  },
  { alpha2Code: "TD", alpha3Code: "TCD", numericCode: "148", name: "Chad" },
  { alpha2Code: "CL", alpha3Code: "CHL", numericCode: "152", name: "Chile" },
  { alpha2Code: "CN", alpha3Code: "CHN", numericCode: "156", name: "China" },
  {
    alpha2Code: "CX",
    alpha3Code: "CXR",
    numericCode: "162",
    name: "Christmas Island",
  },
  {
    alpha2Code: "CC",
    alpha3Code: "CCK",
    numericCode: "166",
    name: "Cocos (Keeling) Islands",
  },
  { alpha2Code: "CO", alpha3Code: "COL", numericCode: "170", name: "Colombia" },
  { alpha2Code: "KM", alpha3Code: "COM", numericCode: "174", name: "Comoros" },
  {
    alpha2Code: "CD",
    alpha3Code: "COD",
    numericCode: "180",
    name: "Democratic Republic of the Congo",
  },
  {
    alpha2Code: "CK",
    alpha3Code: "COK",
    numericCode: "184",
    name: "Cook Islands",
  },
  {
    alpha2Code: "CR",
    alpha3Code: "CRI",
    numericCode: "188",
    name: "Costa Rica",
  },
  {
    alpha2Code: "CI",
    alpha3Code: "CIV",
    numericCode: "384",
    name: "Cote d'Ivoire",
  },
  { alpha2Code: "HR", alpha3Code: "HRV", numericCode: "191", name: "Croatia" },
  { alpha2Code: "CW", alpha3Code: "CUW", numericCode: "531", name: "Curacao" },
  { alpha2Code: "CY", alpha3Code: "CYP", numericCode: "196", name: "Cyprus" },
  {
    alpha2Code: "CZ",
    alpha3Code: "CZE",
    numericCode: "203",
    name: "Czech Republic",
  },
  { alpha2Code: "DK", alpha3Code: "DNK", numericCode: "208", name: "Denmark" },
  { alpha2Code: "DJ", alpha3Code: "DJI", numericCode: "262", name: "Djibouti" },
  { alpha2Code: "DM", alpha3Code: "DMA", numericCode: "212", name: "Dominica" },
  {
    alpha2Code: "DO",
    alpha3Code: "DOM",
    numericCode: "214",
    name: "Dominican Republic",
  },
  { alpha2Code: "EC", alpha3Code: "ECU", numericCode: "218", name: "Ecuador" },
  { alpha2Code: "EG", alpha3Code: "EGY", numericCode: "818", name: "Egypt" },
  {
    alpha2Code: "SV",
    alpha3Code: "SLV",
    numericCode: "222",
    name: "El Salvador",
  },
  {
    alpha2Code: "GQ",
    alpha3Code: "GNQ",
    numericCode: "226",
    name: "Equatorial Guinea",
  },
  { alpha2Code: "ER", alpha3Code: "ERI", numericCode: "232", name: "Eritrea" },
  { alpha2Code: "EE", alpha3Code: "EST", numericCode: "233", name: "Estonia" },
  { alpha2Code: "ET", alpha3Code: "ETH", numericCode: "231", name: "Ethiopia" },
  {
    alpha2Code: "FK",
    alpha3Code: "FLK",
    numericCode: "238",
    name: "Falkland Islands (Malvinas)",
  },
  {
    alpha2Code: "FO",
    alpha3Code: "FRO",
    numericCode: "234",
    name: "Faroe Islands",
  },
  { alpha2Code: "FJ", alpha3Code: "FJI", numericCode: "242", name: "Fiji" },
  { alpha2Code: "FI", alpha3Code: "FIN", numericCode: "246", name: "Finland" },
  { alpha2Code: "FR", alpha3Code: "FRA", numericCode: "250", name: "France" },
  {
    alpha2Code: "GF",
    alpha3Code: "GUF",
    numericCode: "254",
    name: "French Guiana",
  },
  {
    alpha2Code: "PF",
    alpha3Code: "PYF",
    numericCode: "258",
    name: "French Polynesia",
  },
  {
    alpha2Code: "TF",
    alpha3Code: "ATF",
    numericCode: "260",
    name: "French Southern and Antarctic Lands",
  },
  { alpha2Code: "GA", alpha3Code: "GAB", numericCode: "266", name: "Gabon" },
  { alpha2Code: "GM", alpha3Code: "GMB", numericCode: "270", name: "Gambia" },
  { alpha2Code: "GE", alpha3Code: "GEO", numericCode: "268", name: "Georgia" },
  { alpha2Code: "DE", alpha3Code: "DEU", numericCode: "276", name: "Germany" },
  { alpha2Code: "GH", alpha3Code: "GHA", numericCode: "288", name: "Ghana" },
  {
    alpha2Code: "GI",
    alpha3Code: "GIB",
    numericCode: "292",
    name: "Gibraltar",
  },
  { alpha2Code: "GR", alpha3Code: "GRC", numericCode: "300", name: "Greece" },
  {
    alpha2Code: "GL",
    alpha3Code: "GRL",
    numericCode: "304",
    name: "Greenland",
  },
  { alpha2Code: "GD", alpha3Code: "GRD", numericCode: "308", name: "Grenada" },
  {
    alpha2Code: "GP",
    alpha3Code: "GLP",
    numericCode: "312",
    name: "Guadeloupe",
  },
  { alpha2Code: "GU", alpha3Code: "GUM", numericCode: "316", name: "Guam" },
  {
    alpha2Code: "GT",
    alpha3Code: "GTM",
    numericCode: "320",
    name: "Guatemala",
  },
  { alpha2Code: "GG", alpha3Code: "GGY", numericCode: "831", name: "Guernsey" },
  { alpha2Code: "GN", alpha3Code: "GIN", numericCode: "324", name: "Guinea" },
  {
    alpha2Code: "GW",
    alpha3Code: "GNB",
    numericCode: "624",
    name: "Guinea-Bissau",
  },
  { alpha2Code: "GY", alpha3Code: "GUY", numericCode: "328", name: "Guyana" },
  { alpha2Code: "HT", alpha3Code: "HTI", numericCode: "332", name: "Haiti" },
  {
    alpha2Code: "HM",
    alpha3Code: "HMD",
    numericCode: "334",
    name: "Heard Island and McDonald Islands",
  },
  {
    alpha2Code: "VA",
    alpha3Code: "VAT",
    numericCode: "336",
    name: "Vatican City Holy See",
  },
  { alpha2Code: "HN", alpha3Code: "HND", numericCode: "340", name: "Honduras" },
  {
    alpha2Code: "HK",
    alpha3Code: "HKG",
    numericCode: "344",
    name: "Hong Kong",
  },
  { alpha2Code: "HU", alpha3Code: "HUN", numericCode: "348", name: "Hungary" },
  { alpha2Code: "IS", alpha3Code: "ISL", numericCode: "352", name: "Iceland" },
  { alpha2Code: "IN", alpha3Code: "IND", numericCode: "356", name: "India" },
  {
    alpha2Code: "ID",
    alpha3Code: "IDN",
    numericCode: "360",
    name: "Indonesia",
  },
  { alpha2Code: "IR", alpha3Code: "IRN", numericCode: "364", name: "Iran" },
  { alpha2Code: "IQ", alpha3Code: "IRQ", numericCode: "368", name: "Iraq" },
  { alpha2Code: "IE", alpha3Code: "IRL", numericCode: "372", name: "Ireland" },
  {
    alpha2Code: "IM",
    alpha3Code: "IMN",
    numericCode: "833",
    name: "Isle of Man",
  },
  { alpha2Code: "IL", alpha3Code: "ISR", numericCode: "376", name: "Israel" },
  { alpha2Code: "IT", alpha3Code: "ITA", numericCode: "380", name: "Italy" },
  { alpha2Code: "JM", alpha3Code: "JAM", numericCode: "388", name: "Jamaica" },
  { alpha2Code: "JP", alpha3Code: "JPN", numericCode: "392", name: "Japan" },
  { alpha2Code: "JE", alpha3Code: "JEY", numericCode: "832", name: "Jersey" },
  { alpha2Code: "JO", alpha3Code: "JOR", numericCode: "400", name: "Jordan" },
  {
    alpha2Code: "KZ",
    alpha3Code: "KAZ",
    numericCode: "398",
    name: "Kazakhstan",
  },
  { alpha2Code: "KE", alpha3Code: "KEN", numericCode: "404", name: "Kenya" },
  { alpha2Code: "KI", alpha3Code: "KIR", numericCode: "296", name: "Kiribati" },
  {
    alpha2Code: "KR",
    alpha3Code: "KOR",
    numericCode: "410",
    name: "South Korea",
  },
  { alpha2Code: "KW", alpha3Code: "KWT", numericCode: "414", name: "Kuwait" },
  {
    alpha2Code: "KG",
    alpha3Code: "KGZ",
    numericCode: "417",
    name: "Kyrgyzstan",
  },
  { alpha2Code: "LA", alpha3Code: "LAO", numericCode: "418", name: "Laos Lao" },
  { alpha2Code: "LV", alpha3Code: "LVA", numericCode: "428", name: "Latvia" },
  { alpha2Code: "LB", alpha3Code: "LBN", numericCode: "422", name: "Lebanon" },
  { alpha2Code: "LS", alpha3Code: "LSO", numericCode: "426", name: "Lesotho" },
  {
    alpha2Code: "LY",
    alpha3Code: "LBY",
    numericCode: "434",
    name: "Libya Libyan Arab Jamahiriya",
  },
  {
    alpha2Code: "LI",
    alpha3Code: "LIE",
    numericCode: "438",
    name: "Liechtenstein",
  },
  {
    alpha2Code: "LT",
    alpha3Code: "LTU",
    numericCode: "440",
    name: "Lithuania",
  },
  {
    alpha2Code: "LU",
    alpha3Code: "LUX",
    numericCode: "442",
    name: "Luxembourg",
  },
  {
    alpha2Code: "MO",
    alpha3Code: "MAC",
    numericCode: "446",
    name: "Macau Macao",
  },
  {
    alpha2Code: "MK",
    alpha3Code: "MKD",
    numericCode: "807",
    name: "Macedonia",
  },
  {
    alpha2Code: "MG",
    alpha3Code: "MDG",
    numericCode: "450",
    name: "Madagascar",
  },
  { alpha2Code: "MW", alpha3Code: "MWI", numericCode: "454", name: "Malawi" },
  { alpha2Code: "MY", alpha3Code: "MYS", numericCode: "458", name: "Malaysia" },
  { alpha2Code: "MV", alpha3Code: "MDV", numericCode: "462", name: "Maldives" },
  { alpha2Code: "ML", alpha3Code: "MLI", numericCode: "466", name: "Mali" },
  { alpha2Code: "MT", alpha3Code: "MLT", numericCode: "470", name: "Malta" },
  {
    alpha2Code: "MH",
    alpha3Code: "MHL",
    numericCode: "584",
    name: "Marshall Islands",
  },
  {
    alpha2Code: "MQ",
    alpha3Code: "MTQ",
    numericCode: "474",
    name: "Martinique",
  },
  {
    alpha2Code: "MR",
    alpha3Code: "MRT",
    numericCode: "478",
    name: "Mauritania",
  },
  {
    alpha2Code: "MU",
    alpha3Code: "MUS",
    numericCode: "480",
    name: "Mauritius",
  },
  { alpha2Code: "YT", alpha3Code: "MYT", numericCode: "175", name: "Mayotte" },
  { alpha2Code: "MX", alpha3Code: "MEX", numericCode: "484", name: "Mexico" },
  {
    alpha2Code: "FM",
    alpha3Code: "FSM",
    numericCode: "583",
    name: "Micronesia",
  },
  { alpha2Code: "MD", alpha3Code: "MDA", numericCode: "498", name: "Moldova" },
  { alpha2Code: "MC", alpha3Code: "MCO", numericCode: "492", name: "Monaco" },
  { alpha2Code: "MN", alpha3Code: "MNG", numericCode: "496", name: "Mongolia" },
  {
    alpha2Code: "ME",
    alpha3Code: "MNE",
    numericCode: "499",
    name: "Montenegro",
  },
  {
    alpha2Code: "MS",
    alpha3Code: "MSR",
    numericCode: "500",
    name: "Montserrat",
  },
  { alpha2Code: "MA", alpha3Code: "MAR", numericCode: "504", name: "Morocco" },
  {
    alpha2Code: "MZ",
    alpha3Code: "MOZ",
    numericCode: "508",
    name: "Mozambique",
  },
  { alpha2Code: "MM", alpha3Code: "MMR", numericCode: "104", name: "Myanmar" },
  { alpha2Code: "NA", alpha3Code: "NAM", numericCode: "516", name: "Namibia" },
  { alpha2Code: "NR", alpha3Code: "NRU", numericCode: "520", name: "Nauru" },
  { alpha2Code: "NP", alpha3Code: "NPL", numericCode: "524", name: "Nepal" },
  {
    alpha2Code: "NL",
    alpha3Code: "NLD",
    numericCode: "528",
    name: "Netherlands",
  },
  {
    alpha2Code: "AN",
    alpha3Code: "ANT",
    numericCode: "530",
    name: "Netherlands Antilles",
  },
  {
    alpha2Code: "NC",
    alpha3Code: "NCL",
    numericCode: "540",
    name: "New Caledonia",
  },
  {
    alpha2Code: "NZ",
    alpha3Code: "NZL",
    numericCode: "554",
    name: "New Zealand",
  },
  {
    alpha2Code: "NI",
    alpha3Code: "NIC",
    numericCode: "558",
    name: "Nicaragua",
  },
  { alpha2Code: "NE", alpha3Code: "NER", numericCode: "562", name: "Niger" },
  { alpha2Code: "NG", alpha3Code: "NGA", numericCode: "566", name: "Nigeria" },
  { alpha2Code: "NU", alpha3Code: "NIU", numericCode: "570", name: "Niue" },
  {
    alpha2Code: "NF",
    alpha3Code: "NFK",
    numericCode: "574",
    name: "Norfolk Island Norfolk Island",
  },
  {
    alpha2Code: "MP",
    alpha3Code: "MNP",
    numericCode: "580",
    name: "Northern Mariana Islands",
  },
  { alpha2Code: "NO", alpha3Code: "NOR", numericCode: "578", name: "Norway" },
  { alpha2Code: "OM", alpha3Code: "OMN", numericCode: "512", name: "Oman" },
  { alpha2Code: "PK", alpha3Code: "PAK", numericCode: "586", name: "Pakistan" },
  { alpha2Code: "PW", alpha3Code: "PLW", numericCode: "585", name: "Palau" },
  {
    alpha2Code: "PS",
    alpha3Code: "PSE",
    numericCode: "275",
    name: "Palestinian Territory",
  },
  { alpha2Code: "PA", alpha3Code: "PAN", numericCode: "591", name: "Panama" },
  {
    alpha2Code: "PG",
    alpha3Code: "PNG",
    numericCode: "598",
    name: "Papua New Guinea",
  },
  { alpha2Code: "PY", alpha3Code: "PRY", numericCode: "600", name: "Paraguay" },
  { alpha2Code: "PE", alpha3Code: "PER", numericCode: "604", name: "Peru" },
  {
    alpha2Code: "PH",
    alpha3Code: "PHL",
    numericCode: "608",
    name: "Philippines",
  },
  {
    alpha2Code: "PN",
    alpha3Code: "PCN",
    numericCode: "612",
    name: "Pitcairn Islands",
  },
  { alpha2Code: "PL", alpha3Code: "POL", numericCode: "616", name: "Poland" },
  { alpha2Code: "PT", alpha3Code: "PRT", numericCode: "620", name: "Portugal" },
  {
    alpha2Code: "PR",
    alpha3Code: "PRI",
    numericCode: "630",
    name: "Puerto Rico",
  },
  { alpha2Code: "QA", alpha3Code: "QAT", numericCode: "634", name: "Qatar" },
  { alpha2Code: "RE", alpha3Code: "REU", numericCode: "638", name: "Reunion" },
  { alpha2Code: "RO", alpha3Code: "ROU", numericCode: "642", name: "Romania" },
  {
    alpha2Code: "RU",
    alpha3Code: "RUS",
    numericCode: "643",
    name: "Russian Federation",
  },
  { alpha2Code: "RW", alpha3Code: "RWA", numericCode: "646", name: "Rwanda" },
  {
    alpha2Code: "SH",
    alpha3Code: "SHN",
    numericCode: "654",
    name: "Saint Helena",
  },
  {
    alpha2Code: "KN",
    alpha3Code: "KNA",
    numericCode: "659",
    name: "Saint Kitts and Nevis",
  },
  {
    alpha2Code: "LC",
    alpha3Code: "LCA",
    numericCode: "662",
    name: "Saint Lucia",
  },
  {
    alpha2Code: "PM",
    alpha3Code: "SPM",
    numericCode: "666",
    name: "Saint Pierre and Miquelon",
  },
  {
    alpha2Code: "VC",
    alpha3Code: "VCT",
    numericCode: "670",
    name: "Saint Vincent and the Grenadines",
  },
  { alpha2Code: "WS", alpha3Code: "WSM", numericCode: "882", name: "Samoa" },
  {
    alpha2Code: "SM",
    alpha3Code: "SMR",
    numericCode: "674",
    name: "San Marino",
  },
  {
    alpha2Code: "ST",
    alpha3Code: "STP",
    numericCode: "678",
    name: "Sao Tome and Principe",
  },
  {
    alpha2Code: "SA",
    alpha3Code: "SAU",
    numericCode: "682",
    name: "Saudi Arabia",
  },
  { alpha2Code: "SN", alpha3Code: "SEN", numericCode: "686", name: "Senegal" },
  { alpha2Code: "RS", alpha3Code: "SRB", numericCode: "688", name: "Serbia" },
  {
    alpha2Code: "SC",
    alpha3Code: "SYC",
    numericCode: "690",
    name: "Seychelles",
  },
  {
    alpha2Code: "SL",
    alpha3Code: "SLE",
    numericCode: "694",
    name: "Sierra Leone",
  },
  {
    alpha2Code: "SG",
    alpha3Code: "SGP",
    numericCode: "702",
    name: "Singapore",
  },
  { alpha2Code: "SK", alpha3Code: "SVK", numericCode: "703", name: "Slovakia" },
  { alpha2Code: "SI", alpha3Code: "SVN", numericCode: "705", name: "Slovenia" },
  {
    alpha2Code: "SB",
    alpha3Code: "SLB",
    numericCode: "090",
    name: "Solomon Islands",
  },
  {
    alpha2Code: "ZA",
    alpha3Code: "ZAF",
    numericCode: "710",
    name: "South Africa",
  },
  {
    alpha2Code: "GS",
    alpha3Code: "SGS",
    numericCode: "239",
    name: "South Georgia and the South Sandwich Islands",
  },
  { alpha2Code: "ES", alpha3Code: "ESP", numericCode: "724", name: "Spain" },
  {
    alpha2Code: "LK",
    alpha3Code: "LKA",
    numericCode: "144",
    name: "Sri Lanka",
  },
  { alpha2Code: "SR", alpha3Code: "SUR", numericCode: "740", name: "Suriname" },
  {
    alpha2Code: "SJ",
    alpha3Code: "SJM",
    numericCode: "744",
    name: "Svalbard and Jan Mayen",
  },
  {
    alpha2Code: "SZ",
    alpha3Code: "SWZ",
    numericCode: "748",
    name: "Swaziland",
  },
  { alpha2Code: "SE", alpha3Code: "SWE", numericCode: "752", name: "Sweden" },
  {
    alpha2Code: "CH",
    alpha3Code: "CHE",
    numericCode: "756",
    name: "Switzerland",
  },
  { alpha2Code: "TW", alpha3Code: "TWN", numericCode: "158", name: "Taiwan" },
  {
    alpha2Code: "TJ",
    alpha3Code: "TJK",
    numericCode: "762",
    name: "Tajikistan",
  },
  { alpha2Code: "TZ", alpha3Code: "TZA", numericCode: "834", name: "Tanzania" },
  { alpha2Code: "TH", alpha3Code: "THA", numericCode: "764", name: "Thailand" },
  {
    alpha2Code: "TL",
    alpha3Code: "TLS",
    numericCode: "626",
    name: "East Timor",
  },
  { alpha2Code: "TG", alpha3Code: "TGO", numericCode: "768", name: "Togo" },
  { alpha2Code: "TK", alpha3Code: "TKL", numericCode: "772", name: "Tokelau" },
  { alpha2Code: "TO", alpha3Code: "TON", numericCode: "776", name: "Tonga" },
  {
    alpha2Code: "TT",
    alpha3Code: "TTO",
    numericCode: "780",
    name: "Trinidad and Tobago",
  },
  { alpha2Code: "TN", alpha3Code: "TUN", numericCode: "788", name: "Tunisia" },
  { alpha2Code: "TR", alpha3Code: "TUR", numericCode: "792", name: "Turkey" },
  {
    alpha2Code: "TM",
    alpha3Code: "TKM",
    numericCode: "795",
    name: "Turkmenistan",
  },
  {
    alpha2Code: "TC",
    alpha3Code: "TCA",
    numericCode: "796",
    name: "Turks and Caicos Islands",
  },
  { alpha2Code: "TV", alpha3Code: "TUV", numericCode: "798", name: "Tuvalu" },
  { alpha2Code: "UG", alpha3Code: "UGA", numericCode: "800", name: "Uganda" },
  { alpha2Code: "UA", alpha3Code: "UKR", numericCode: "804", name: "Ukraine" },
  {
    alpha2Code: "AE",
    alpha3Code: "ARE",
    numericCode: "784",
    name: "United Arab Emirates",
  },
  {
    alpha2Code: "UM",
    alpha3Code: "UMI",
    numericCode: "581",
    name: "United States Minor Outlying Islands",
  },
  { alpha2Code: "UY", alpha3Code: "URY", numericCode: "858", name: "Uruguay" },
  {
    alpha2Code: "UZ",
    alpha3Code: "UZB",
    numericCode: "860",
    name: "Uzbekistan",
  },
  { alpha2Code: "VU", alpha3Code: "VUT", numericCode: "548", name: "Vanuatu" },
  {
    alpha2Code: "VE",
    alpha3Code: "VEN",
    numericCode: "862",
    name: "Venezuela, Bolivarian Republic of",
  },
  { alpha2Code: "VN", alpha3Code: "VNM", numericCode: "704", name: "Viet Nam" },
  {
    alpha2Code: "VG",
    alpha3Code: "VGB",
    numericCode: "092",
    name: "British Virgin Islands",
  },
  {
    alpha2Code: "VI",
    alpha3Code: "VIR",
    numericCode: "850",
    name: "United States Virgin Islands",
  },
  {
    alpha2Code: "WF",
    alpha3Code: "WLF",
    numericCode: "876",
    name: "Wallis and Futuna",
  },
  {
    alpha2Code: "EH",
    alpha3Code: "ESH",
    numericCode: "732",
    name: "Western Sahara",
  },
  { alpha2Code: "YE", alpha3Code: "YEM", numericCode: "887", name: "Yemen" },
  { alpha2Code: "ZM", alpha3Code: "ZMB", numericCode: "894", name: "Zambia" },
  {
    alpha2Code: "MF",
    alpha3Code: "MAF",
    numericCode: "663",
    name: "Saint Martin",
  },
  {
    alpha2Code: "BQ",
    alpha3Code: "BES",
    numericCode: "535",
    name: "Bonaire, Sint Eustatius and Saba",
  },
  {
    alpha2Code: "BL",
    alpha3Code: "BLM",
    numericCode: "652",
    name: "Saint Barthelemy",
  },
]

export const wireIban: string[] = [
  "AL",
  "AD",
  "AT",
  "AZ",
  "BH",
  "BY",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FO",
  "FI",
  "FR",
  "GE",
  "DE",
  "GI",
  "GR",
  "GG",
  "HU",
  "IS",
  "IE",
  "IM",
  "IL",
  "IT",
  "JE",
  "JO",
  "KW",
  "LV",
  "LB",
  "LI",
  "LT",
  "LU",
  "MT",
  "MR",
  "MD",
  "MC",
  "NL",
  "NO",
  "PK",
  "PS",
  "PL",
  "PT",
  "QA",
  "RO",
  "SM",
  "SA",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
  "TN",
  "TR",
  "AE",
  "GB",
]

export const PAYMENT_BY_CHECK_MAIL = 1
export const PAYMENT_BY_WIRE_TRANSFER = 2
export const PAYMENT_BY_CHECK_FEDEX = 3
export const PAYMENT_BY_PAXUM = 4
export const PAYMENT_BY_ACH = 7
export const PAYMENT_BY_COSMO = 8
export const PAYMENT_BY_EPAYMENTS = 9
export const PAYMENT_BY_BTC = 11
export const PAYMENT_BY_EPAYSERVICE = 12
export const PAYMENT_BY_COEFT = 13
export const PAYMENT_BY_SKRILL = 14

export enum ALERT_SEVERITY_LEVEL {
  error = "error",
  success = "success",
}

export const ONLY_FANS_GOOGLE_CHROME_EXT_URL =
  "https://chrome.google.com/webstore/detail/mmailkaieiofipojbfgebhlnnbenfdnp"

export const PAYMENT_BY_CRYPTO = 15

export const ACCEPTED_VIDEO_FORMATS = {
  "video/mp4": [],
  "video/quicktime": [],
  // "video/x-msvideo": [], remove avi for now
  "application/x-mpegURL": [],
  "video/MP2T": [],
  "video/x-ms-wmv": [],
}

export const ACCEPTED_IMAGE_FORMATS = {
  "image/gif": [],
  "image/jpeg": [],
  "image/jpg": [],
  "image/png": [],
}

export const CHAT_MEDIA_MAX_FILES = 20

export const PROMO_DISPUTE_MEDIA_MAX_FILES = 10

export const priceOptions = ["Any Price", "Under $100", "$100 - $500", "$500 +"]
export const followingOptions = [
  "Any Following",
  "Under 2K",
  "2K - 10K",
  "10K - 20K",
  "20K + ",
]
export const percentageOptions = [
  "Any OF %",
  "OF Top 0.1%",
  "OF Top 1%",
  "OF Top 5%",
]

export const promoTypes = [
  {
    platform: ["X/Twitter"],
    type: "Assistant Services",
  },
  {
    platform: [
      "Instagram",
      "X/Twitter",
      "Reddit",
      "TikTok",
      "OnlyFans",
      "Fansly",
      "Facebook",
      "YouTube",
      "ManyVids",
    ],
    type: "Feed Post",
  },
  {
    platform: ["OnlyFans"],
    type: "Friend Spot",
  },
  {
    platform: [
      "OnlyFans",
      "Fansly",
      "X/Twitter",
      "Reddit",
      "ManyVids",
      "Instagram",
    ],
    type: "Guaranteed Gains",
  },
  {
    platform: ["OnlyFans", "Fansly", "ManyVids"],
    type: "Mass DM",
  },
  {
    platform: ["YouTube"],
    type: "Mention",
  },
  {
    platform: [
      "OnlyFans",
      "Fansly",
      "Instagram",
      "X/Twitter",
      "Reddit",
      "TikTok",
      "Facebook",
    ],
    type: "Pinned Post",
  },
  {
    platform: ["Service Provider"],
    type: "Professional Services",
  },
  {
    platform: ["Instagram"],
    type: "Reel Post",
  },
  {
    platform: ["X/Twitter", "ManyVids", "TikTok"],
    type: "Repost",
  },
  {
    platform: ["YouTube"],
    type: "Short",
  },
  {
    platform: ["Instagram", "Snapchat", "OnlyFans", "Fansly", "Facebook"],
    type: "Story Post",
  },
  {
    platform: ["Instagram", "Snapchat", "Facebook"],
    type: "Story Share",
  },
]

export const defaultTags = [
  "Bisexual",
  "Female",
  "Gay",
  "Lesbian",
  "Male",
  "Nonbinary",
  "Straight",
]

export const TFA_PHONE_DEVICE = "PhoneDevice"
export const TFA_APP_DEVICE = "TOTPDevice"

export const FILE_UPLOAD_SIZE_ERROR = "File size is greater than 200MB!"

export const vapidPublicKey =
  "BOUQ7COM07VXHtRPq2iJVpf1NiVG6q-hVNovQjHQ1JA8bne2dxjG4ebcnOWiSM5MOA5oOnYtUvow30GzUH6da44"
