import React from "react"
import { Stack, SxProps, useTheme } from "@mui/material"
import { Theme } from "@mui/material/styles"
import { Colors } from "../../../styles/Colors"

interface IGroupsStyleProps {
  isMobile: boolean
  theme: Theme
  centerContent: boolean
  maxHeight: string
  margin: string
}
interface IGroupsStyle {
  stylized: SxProps<Theme>
  husk: SxProps<Theme>
}
const getGroupsStyle = ({
  isMobile,
  theme,
  centerContent,
  maxHeight,
  margin,
}: IGroupsStyleProps): IGroupsStyle => {
  const sharedContainerStyles = {
    margin: margin,
    padding: 0,
    boxSizing: "border-box",
    maxHeight: maxHeight,
    width: "100%",
    justifyContent: centerContent ? "center" : "unset",
    "& > *": {
      minWidth: "280px",
      alignSelf: centerContent ? "center" : "stretch",
    },
  }
  return {
    stylized: {
      ...sharedContainerStyles,
      paddingTop: isMobile ? "16px" : "32px",
      paddingLeft: isMobile ? "1.2rem" : "32px",
      paddingRight: isMobile ? "1.2rem" : "32px",
      backgroundColor: theme.palette.background.default,
      "& > *": {
        ...sharedContainerStyles["& > *"],
        flex: "1",
      },
    },
    husk: {
      ...sharedContainerStyles,
      maxHeight: "100%",
      overflowY: "auto",
      backgroundColor: Colors.transparent,
    },
  }
}

interface IGroupStyleProps {
  vPadding: string
  hPadding: string
  backgroundColor: string
  centerContent: boolean
  applyDropShadow: boolean
  listItem: boolean
}
interface IGroupStyle {
  stylized: SxProps<Theme>
  husk: SxProps<Theme>
  listItem: SxProps<Theme>
  lastListItem: SxProps<Theme>
}
const getGroupStyle = ({
  vPadding,
  backgroundColor,
  hPadding,
  centerContent,
  applyDropShadow,
  listItem,
}: IGroupStyleProps): IGroupStyle => {
  const sharedStyles = {
    margin: "0px !important",
    padding: (vPadding ?? "0px") + " " + (hPadding ?? "0px"),
    height: "fit-content",
    width: "100%",
    display: "flex",
    gap: "8px",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "1rem",
    backgroundColor,
    boxShadow: applyDropShadow
      ? "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)"
      : listItem
      ? "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)"
      : "inherit",
  }
  return {
    stylized: {
      ...sharedStyles,
      alignSelf: centerContent ? "center" : "stretch",
      marginBottom: "16px !important",
    },
    husk: {
      ...sharedStyles,
      alignSelf: centerContent ? "center" : "stretch",
    },
    listItem: {
      ...sharedStyles,
      alignSelf: "stretch",
      borderRadius: "0px",
      border: "solid",
      borderTop: "none",
      borderColor: "rgba(0, 0, 0, 0.06)",
    },
    lastListItem: {
      ...sharedStyles,
      alignSelf: "stretch",
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      border: "solid",
      borderTop: "none",
      borderColor: "rgba(0, 0, 0, 0.06)",
    },
  }
}

interface IGroupsProps {
  children: React.ReactElement | React.ReactElement[]
  TopBanner?: React.ReactElement
  useColumnLayout: boolean
  centerContent: boolean
  isMobile: boolean
  maxHeight?: string
  husk?: boolean
  margin?: string
}
export const Groups = ({
  children,
  useColumnLayout,
  centerContent,
  isMobile,
  maxHeight = isMobile ? "auto" : "calc(100% - 64px)",
  TopBanner,
  margin = `0 0 ${isMobile ? "16px" : "32px"} 0`,
  husk = false,
}: IGroupsProps): JSX.Element => {
  const theme = useTheme()
  const groupsStyle = getGroupsStyle({
    isMobile,
    theme,
    centerContent,
    maxHeight,
    margin,
  })

  return (
    <Stack sx={groupsStyle.husk}>
      {TopBanner && (
        <Group
          centerContent={centerContent}
          husk={true}
          isMobile={isMobile}
          vPadding={"0px"}
          hPadding={isMobile ? "1.2rem" : "32px"}
        >
          {TopBanner}
        </Group>
      )}
      <Stack
        direction={useColumnLayout ? "column" : "row"}
        gap={isMobile ? 0 : 2}
        sx={husk ? groupsStyle.husk : groupsStyle.stylized}
      >
        {children}
      </Stack>
    </Stack>
  )
}

export interface IGroup {
  children: React.ReactElement | React.ReactElement[]
  isMobile: boolean
  vPadding: string
  hPadding?: string
  background?: string
  centerContent: boolean
  applyDropShadow?: boolean
  husk?: boolean
  listItem?: boolean
  lastInList?: boolean
  maxWidth?: string
}

export const Group = ({
  children,
  isMobile,
  centerContent,
  vPadding,
  hPadding = isMobile ? "16px" : "24px",
  applyDropShadow = false,
  background,
  husk = false,
  listItem = false,
  lastInList = false,
  maxWidth,
}: IGroup): JSX.Element => {
  const theme = useTheme()
  const backgroundColor = background
    ? background
    : husk
    ? Colors.transparent
    : theme.palette.background.paper
  const groupStyle = getGroupStyle({
    vPadding,
    hPadding,
    backgroundColor,
    centerContent,
    listItem,
    applyDropShadow,
  })
  const groupType = lastInList
    ? groupStyle.lastListItem
    : listItem
    ? groupStyle.listItem
    : husk
    ? groupStyle.husk
    : groupStyle.stylized
  return (
    <Stack sx={{ ...groupType, maxWidth: maxWidth + " !important" }}>
      {children}
    </Stack>
  )
}
