import {
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Search } from "@mui/icons-material"
import styled from "@emotion/styled"
import {
  HorizontalProfileCard,
  IProfileCardProps,
} from "./HorizontalProfileCard"
import { useEffect } from "react"

export interface ISearchProfilesProps {
  profiles: IProfileCardProps[]
  searchProfiles: (term: string) => void
}

export const SearchResults = ({
  profiles,
  searchProfiles,
}: ISearchProfilesProps): JSX.Element => {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    searchProfiles("")
  }, [])

  return (
    <StyledDiv>
      <TextField
        className="profile-search-input"
        variant={"outlined"}
        label={"Search for Creators"}
        InputProps={{
          endAdornment: <Search />,
        }}
        onChange={(event) => {
          searchProfiles(event.target.value)
        }}
      />
      <div className="search-results-container">
        {profiles.length === 0 ? (
          <Typography variant={"h6"} color={theme.palette.text.secondary}>
            <Stack
              direction={"column"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                textAlign: "center",
                width: "100%",
                margin: "0px auto",
                paddingTop: "100px",
                height: isMobileView ? "78vh" : "74vh",
              }}
            >
              <p>
                There are no profiles matching your current search criteria.
              </p>
            </Stack>
          </Typography>
        ) : (
          profiles.map((profile, index: number) => {
            return (
              <HorizontalProfileCard
                {...profile}
                forceMobile={true}
                key={index}
              />
            )
          })
        )}
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;

  .profile-card {
    margin-top: 18px;
  }

  .profile-search-input {
    width: 100%;
  }

  .search-results-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: calc(100vh - 146px);
    margin-top: 19px;
    overflow-y: auto;
    padding: 0 4px 4px 4px;
  }
`
